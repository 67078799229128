const axios = require('axios');

export default {
    name: 'search',
    data() {
        return {
            search: {
                type: null,
                mode: null,
                settlement: ''
            },
            sale: true,
            estates: []
        }
    },
    methods: {
        setSearch: async function(type, mode) {
            if(this.$route.query === {type, mode}) return;
            if(this.search.type !== type) this.search.type = type;
            if(this.search.mode !== mode) this.search.mode = mode;
            await this.$router.push({name: 'search', params: this.search});
            if(this.search.type && this.search.mode) await this.list();
        },
        list: async function() {
            let response = await axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/public/list/estate?estate=${this.search.type}&deal=${this.search.mode}`,
                headers: { source: process.env.VUE_APP_PUBLIC_KEY },
                query: {
                    estate: this.search.type,
                    deal: this.search.mode,
                    settlement: this.search.settlement
                }
            });
            this.estates = response.data;
        }
    },
    async beforeRouteUpdate(to, from, next) {
        this.search = to.params;
        if(this.search.type && this.search.mode) await this.list();
        next();
    },
    async mounted() {
      this.search = this.$route.params;
      if(this.search.type && this.search.mode) await this.list();
    }
}
